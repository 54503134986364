import type { SvelteAction } from '@tickrr/lib/types';

import { prefersReducedMotionStore } from '@skeletonlabs/skeleton';
import { get } from 'svelte/store';

type FadeInOnIntersectOptions = {
	delay?: number;
	duration?: number;
	enabled?: boolean;
	threshold?: number;
	translate?: `translate-${'x' | 'y'}-${number}`;
};

/**
 * Fades in an element when it intersects the viewport.
 * @param node The element to fade in.
 * @param opts The options for the fade in.
 * @returns An object with a `destroy` method to remove the observer.
 */
export const fadeInOnIntersect: SvelteAction<FadeInOnIntersectOptions> = (
	node: HTMLElement,
	opts: FadeInOnIntersectOptions
) => {
	const delay = opts.delay ?? 0;
	const enabled = opts.enabled ?? get(prefersReducedMotionStore) === false;
	const threshold = opts.threshold ?? 0.05;
	const translate = opts.translate ?? undefined;

	if (!enabled) {
		return {
			/**
			 * TODO: we MAY need to return an update() method that recursively call this function below, for toggling of the
			 * TODO: enabled property to work properly.
			 *
			 * @example
			 * ```ts
			 * update(parameter) {
			 * 	if (parameter.enabled === true) {
			 * 		return fadeInOnIntersect(node, parameter);
			 * 	}
			 * }
			 * ```
			 */
		};
	}

	function handleIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
		if (entries[0].isIntersecting) {
			setTimeout(() => {
				node.classList.remove('opacity-0');
				if (translate) node.classList.remove(translate);
				observer.unobserve(node);
			}, delay);
		}
	}

	// This MUST precede instantiation of the observer.
	// The transition and duration classes will not be applied if there is no delay set,
	// UNLESS we add the classes 1st like so.
	node.style.transitionProperty = opts.translate ? 'opacity, transform' : 'opacity';
	node.style.transitionTimingFunction = 'cubic-bezier(0.4, 0, 0.2, 1)';
	node.style.transitionDuration = `${opts.duration ?? 1000}ms`;
	node.classList.add('opacity-0');
	if (opts.translate) node.classList.add(opts.translate);

	const observer = new IntersectionObserver((entries) => handleIntersect(entries, observer), {
		threshold
	});

	observer.observe(node);

	return {
		destroy() {
			observer.unobserve(node);
		}
	};
};
